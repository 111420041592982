import axios from "axios"

import {
  STORE_ORDER_LOADING,
  STORE_ORDER_SUCCESS,
  STORE_ORDER_FAIL,
  GET_ORDERS_LOADING,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAIL,
  GET_ORDER_DETAIL_LOADING,
  GET_ORDER_DETAIL_SUCCESS,
  GET_ORDER_DETAIL_FAIL,
} from "./types"
import { BASE_URL } from "../../utils/urls"

export const storeOrder = order => (dispatch, getState) => {
  dispatch({ type: STORE_ORDER_LOADING })
  return new Promise((resolve, reject) => {
    axios
      .post(BASE_URL + "/api/orders", order, tokenConfig(getState))
      .then(res => {
        if (res.data.success === true) {
          dispatch({ type: STORE_ORDER_SUCCESS, payload: res.data })
          console.log("Success 1", res.data)
          resolve(res.data)
        } else {
          dispatch({ type: STORE_ORDER_FAIL, payload: res.data })
          console.log("Error 1", res.data)
          reject(res.data)
        }
      })
      .catch(err => {
        console.log("Error 2", err.response ? err.response.data : err)
        dispatch({
          type: STORE_ORDER_FAIL,
          payload: err.response ? err.response.data : err,
        })
        reject(err.response ? err.response.data : err)
      })
  })
}

export const getOrders = () => (dispatch, getState) => {
  dispatch({ type: GET_ORDERS_LOADING })
  return new Promise((resolve, reject) => {
    axios
      .get(BASE_URL + "/api/orders", tokenConfig(getState))
      .then(res => {
        dispatch({ type: GET_ORDERS_SUCCESS, payload: res.data })
        console.log("Success get order", res.data)
        resolve(res.data)
      })
      .catch(err => {
        console.log("Error get order", err.response ? err.response.data : err)
        dispatch({
          type: GET_ORDERS_FAIL,
          payload: err.response ? err.response.data : err,
        })
        reject(err.response ? err.response.data : err)
      })
  })
}

export const getOrderDetail = orderId => (dispatch, getState) => {
  dispatch({ type: GET_ORDER_DETAIL_LOADING })
  return new Promise((resolve, reject) => {
    axios
      .get(BASE_URL + "/api/orders/" + orderId, tokenConfig(getState))
      .then(res => {
        dispatch({ type: GET_ORDER_DETAIL_SUCCESS, payload: res.data })
        console.log("Success get order detail", res.data)
        resolve(res.data)
      })
      .catch(err => {
        console.log(
          "Error get order detail",
          err.response ? err.response.data : err
        )
        dispatch({
          type: GET_ORDER_DETAIL_FAIL,
          payload: err.response ? err.response.data : err,
        })
        reject(err.response ? err.response.data : err)
      })
  })
}

export const tokenConfig = getState => {
  // Get token from localStorage
  const token = getState().auth.token

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  }
  if (token) {
    config.headers["Authorization"] = "Bearer " + token
  }

  return config
}
