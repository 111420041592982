import React, { useEffect } from "react"
import { connect } from "react-redux"
import moment from "moment"
import "react-toastify/dist/ReactToastify.css"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Slider2 from "../components/slider2"
import { getOrders } from "../store/actions/orderActions"
import { Link } from "gatsby"

const Orders = ({ orders, getOrders, isLoading }) => {
  useEffect(() => {
    getOrders()
  }, [])
  const pageName = "MES COMMANDES"
  return (
    <Layout>
      <SEO title={pageName} />
      <Slider2 title={pageName} />
      <div className="flex flex-wrap container mx-auto py-6">
        {isLoading ? (
          <div
            className="w-full mx-4 bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded relative text-center"
            role="alert"
          >
            <span className="block sm:inline">Chargement en cours ...</span>
          </div>
        ) : (
          <div className="w-full">
            {orders.length === 0 ? (
              <div
                className="mx-4 bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded relative text-center text-sm"
                role="alert"
              >
                <span className="block sm:inline">
                  Aucune commande enregistré 😭.&nbsp;
                  <Link
                    to="/all-clothes"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-full"
                  >
                    Des vêtements sales à nous confier ?
                  </Link>
                </span>
              </div>
            ) : (
              <table className="table-auto text-sm">
                <thead>
                  <tr>
                    <th className="px-4 py-2">#</th>
                    <th className="px-4 py-2">Référence</th>
                    <th className="px-4 py-2">Montant</th>
                    <th className="px-4 py-2">Etape</th>
                    <th className="px-4 py-2">Date</th>
                    <th className="px-4 py-2">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order, key) => (
                    <tr key={order.id}>
                      <td>{key + 1}</td>
                      <td>{order.ref}</td>
                      <td>{order.total_price + " FCFA"}</td>
                      <td>
                        {order.steps.length > 0 ? order.steps[0].name : ""}
                      </td>
                      <td>
                        {moment(order.created_at).format("DD/MM/YYYY à HH:mm")}
                      </td>
                      <td>
                        <Link
                          to={`/user/order/${order.id}`}
                          className="bg-transparent hover:bg-blue-600 text-blue-600 font-semibold hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent rounded"
                        >
                          Détails
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        )}
      </div>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    orders: state.order.orders,
    isLoading: state.order.isLoading,
  }
}

const mapDispatchToProps = {
  getOrders,
}

export default connect(mapStateToProps, mapDispatchToProps)(Orders)
